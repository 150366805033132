<template>
    <div class="page">
        <template-wizard v-if="ready" :item="clone" :clone="(!!clone)"></template-wizard>
    </div>

</template>

<script>
import TemplateWizard from "@/views/app/templates/components/template-wizard";

export default {
  name: "new",
  components: {TemplateWizard},

  data() {
    return {
      clone: null,
      ready: false,
    }
  },

  mounted() {
    if (this.$route.query.clone) {
      this.$talker.api(`templates/${this.$route.query.clone}`)
        .then(res => {
          this.clone = res.data;
          this.ready = true;
        })
    } else {
      this.ready = true;
    }

  },
}
</script>

<style scoped lang="scss">

</style>